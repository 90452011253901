import 'ol/ol.css';
import Feature from 'ol/Feature';
import Map from 'ol/Map';
import View from 'ol/View';
import {Circle,Point} from 'ol/geom';
import {transform} from 'ol/proj';
import {OSM, Vector as VectorSource} from 'ol/source';
import {Style,Icon} from 'ol/style';
import {Tile as TileLayer, Vector as VectorLayer} from 'ol/layer';
import markerIconFrom from 'url:./marker-from.png';
import markerIconTo from 'url:./marker-to.png';

const TOKEN = '127e6fbfe24a750e72930c220a8e138275656b8e5d8f48a98c3c92df2caba936';

let current = { 'longitude': 2.3470527454727916, 'latitude': 48.86283974145563};
let radius = 10;
let map;

const MAP_ID = 'map';
const PROJECTION_SOURCE = 'EPSG:4326';
const PROJECTION_DESTINATION = 'EPSG:3857';
const CIRCLE_COLOR = { red:65, green:165, blue :65 };

const STYLES = {
	'iconMarker': new Style({
		image: new Icon({
			anchor: [0.5, 1],
			src: markerIconFrom,
		}),
	}),
	'iconDestination': new Style({
		image: new Icon({
			anchor: [0.5, 1],
			src: markerIconTo,
		}),
	}),
};

function about(longitude, latitude) {
	return $.ajax({
		type: 'GET',
		url: 'https://nominatim.openstreetmap.org/reverse',
		dataType: 'xml',
		data : {'format': 'xml', 'lat' : latitude, 'lon': longitude, 'zoom':  18, 'addressdetails': 1}
	}).pipe(
		function( xml ) {
			let position = {};

			position.latitude = latitude;
			position.longitude = longitude;
			position.city = $(xml).find('city').text() ? $(xml).find('city').text() : $(xml).find('town').text();
			position.postcode = $(xml).find('postcode').text();
			position.county = $(xml).find('county').text();
			position.country = $(xml).find('country').text();

			return position;
		}
	);
}

function surround(position, options) {
	$("#map").empty();

	$("#my-position-text").val(position.city + ', ' + position.postcode + ', ' + position.county + ', ' + position.country);

	let coordinates = transform([position.longitude, position.latitude], PROJECTION_SOURCE, PROJECTION_DESTINATION);

	let markerFeature = new Feature({
		type:'iconMarker',
		geometry: new Point(coordinates),
	});

	let circleFeature = new Feature({
		geometry: new Circle(coordinates, 1613 * radius),
	});
	circleFeature.setStyle(
		new Style({
			renderer: function renderer(coordinates, state) {
				circle(state.context, {x: coordinates[0][0], y: coordinates[0][1]}, {x: coordinates[1][0], y: coordinates[1][1]}, CIRCLE_COLOR)
			},
		})
	);

	let vectorLayer = new VectorLayer({
		source: new VectorSource({
			features: [circleFeature,markerFeature],
		}),
		style: function (feature) {
			return STYLES[feature.get('type')];
		},
	});

	map = new Map({
		layers: [
			new TileLayer({
				source: new OSM(),
				visible: true,
			}),
			vectorLayer ],
		target: MAP_ID,
		view: new View({
			projection: PROJECTION_DESTINATION,
			center: coordinates,
			zoom: options.scope,
		})
	});

	let extent = vectorLayer.getSource().getExtent();
	map.getView().fit(extent, {size:map.getSize(), maxZoom:options.scope});
}

function circle(context, point0, point1, color) {
	let radius = Math.sqrt((point1.x - point0.x) * (point1.x - point0.x) + (point1.y - point0.y) * (point1.y - point0.y));

	let gradient = context.createRadialGradient(point0.x, point0.y, 0, point0.x, point0.y, radius * 1.4);
	gradient.addColorStop(0, rgba(color, 0));
	gradient.addColorStop(0.6, rgba(color, 0.4));
	gradient.addColorStop(1, rgba(color, 0.6));

	context.beginPath();
	context.arc(point0.x, point0.y, radius, 0, 2 * Math.PI, true);
	context.fillStyle = gradient;
	context.fill();

	context.arc(point0.x, point0.y, radius, 0, 2 * Math.PI, true);
	context.strokeStyle = rgba(color, 1);
	context.stroke();
}

function rgba(color, opacity) {
	return 'rgba('+color.red+','+color.green+','+color.blue+','+opacity+')';
}

function landmark(position) {
	$.ajax({
		url : 'https://predictivelane.com/cities.php',
		type : 'GET',
		data : {'city' : position.city, 'county': position.postcode.substring(0, 2), 'radius': radius, 'token': TOKEN},
		dataType: 'xml',
		success: function(xml) {
			$(xml).find('ville').each( function() {
				let latitude=parseFloat($(this).attr('lat'));
				let longitude=parseFloat($(this).attr('lng'));

				let feature = new Feature({
					type:'iconDestination',
					geometry: new Point(transform([longitude, latitude], PROJECTION_SOURCE, PROJECTION_DESTINATION)),
				});
				let vectorLayer = new VectorLayer({
					source: new VectorSource({
						features: [feature],
					}),
					style: function (feature) {
						return STYLES[feature.get('type')];
					},
				});

				map.addLayer(vectorLayer);

			});
		}

	});
}

function display(options) {
	about(current.longitude, current.latitude).done((position) => {
		surround(position , options);

		if (options.landmarks) {
			landmark(position);
		}
	})
}

$( document ).ready(() => {
	$("#radius-value").change((event) => {
		radius = $(event.target).val();
		console.log('Radius = %skm', radius);

		display({scope: 11, landmarks: true});
	});

	$("#find-me-button").click(() => {
		navigator.geolocation.getCurrentPosition((position) => {
			console.log('Current position : lon = %s x lat = %s', position.coords.longitude, position.coords.latitude);

			current.longitude = position.coords.longitude;
			current.latitude = position.coords.latitude;

			display({scope: 11, landmarks: true});
		});
	});

	display({scope: 4, landmarks: false});
});


